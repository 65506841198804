import { requests } from "../common/network";
import { $dqs } from "../common/utils";
import v from "../common/variable";
import starSelect from "./custom_star_insert";

// 自定义需求标注

/* const keys = {

  products_handle: '',

  // 首页评论区
  isHomeReview: false,

  // 收藏页评论区
  isCollReview: false,

  // 不嵌入无评论区
  isNoNullReview: false,

  // 卸载店铺测试
  isTest: false,

  // 将评论区放在单独页面里
  isPageReview: false,

  // 用户自定义评论数量
  reviewsPageCount: 0,

  // 自定义样式
  vstarCustomSty: '',

  // 程序延迟执行
  executeDelay: 0,

  // 是否所有页面生效
  // isAllPage: false,

  //=========================================
  //===========评论区头部相关==================

  // 评论区头部写评价按钮隐藏
  isNoWriteBtn: false,

  // 评论区头部评分数字是否显示 /5
  isBigRatingNo5: false,

  // 是否移除评论区头部
  isNoRevHead: false,


  //==========================================
  //===========评论区主体相关==================

  // 每条评论显示评分数值
  isShowRevRatingNum: false,

  //显示评论区按钮
  isShowRevBtn: false,

  // 点击产品页跳转评论区，评论区显示
  isShowRevClick: false,


  //是否评论带验证图标
  isShowRevVerified: true,
  isAddRevVrfy: false,

  // 是否开启图片懒加载
  isImgLoadLazy: false,

  // 是否进行评论内容省略
  isNoEllipsisReviews：false

  //==========================================
  //=================添加评论弹窗相关===========

  // 评论弹窗上传图片隐藏
  isNoUploadImg: false,
  //===============================================
  //===============集合页相关=======================

  // 是否收藏页评价带reviews
  isCollRevWord: false,

  // 定制店铺集合页嵌入位置（css选择器）
  customCollTarSel: '',
  // 是否进行集合页嵌入元素匹配
  isOpenCollTarMatch: true,

  //======================================================
  //======================产品页相关=======================

  // 评论数量加()
  isBracketsReviewNum: false,
  // 产品页评价数去掉reviews加() 
  isProBrackets: false,

  //产品页评价显示评分
  isShowProRating: false,

  // 定制店铺产品页星星嵌入位置（css选择器）
  customProTarSel: '',
  // 是否进行产品页嵌入元素匹配
  isOpenProTarMatch: true,


  //====================================================

  //====================用户店铺特殊处理=============//

  isCollAgain: false,
  //收藏页星星嵌入延迟2s
  isCollStartDelay: false,
  homeStarDelay: 0,
  //产品页推荐加载慢延迟嵌入星星
  //小延迟 2s
  isProRecDelay: false,
  //中延迟 3s
  isProRecMDelay: false,

  // 不跳转官网
  notJumpWebsite: false,
} */

const user_need = {
	// 用户需求信息
	get_move_review_info: {},
	custom_carousel_info: {},
	custom_style_info: {},
	star_insert_adaptation_info: {},
	user_needs_solutions_info: {},
	fixedLayout: 0,
	// 评论区头部评分数字位数
	bigRatingDigits: 2,
	// 是否开启产品页推荐产品星星嵌入
	isOpenRecStars: true,
	isShowRevVerified: true,
	isPopupProdReviews: false,
	customRatingText: "", // 自定义星星控件的文本内容
	customHeadRatingText: "",
	isShopNameBold: false,
	showRelatedPros: false, // 产品页产品组评论区是否展示关联产品（需要后端开启）
	setUserNeed() {
		const u = this;
		const { shop_id } = Review;

		//Mark 偶尔需求
		switch (shop_id) {
			case 55420682445: {
				if (v.isProPage) {
					document.body.addEventListener("click", function (e) {
						if (
							e.target.classList.contains("reviews-text") &&
							e.target.tagName === "P"
						) {
							$dqs(".breadcrumb_pr_wrap").scrollIntoView({
								behavior: "smooth",
								block: "start",
							});
						}
					});
				}
				break;
			}
			case 26046955594:
			case 17400017: {
				u.isShopNameBold = true;
				window.trustooAfterExecute = v => {
					if (v.source === "reviews") {
						v.globalStyle.textContent += `
						#vstar-reviews.trustoo-widget .tt-qa .tt-answerer,#vstar-reviews.trustoo-widget .merchant-reply div {
							font-weight: 400 !important;
					}
						`;
					}
				};
				break;
			}
		}
		Object.assign(u, starSelect());
	},
	setPageReview(shop_id) {
		//Mark 添加页面评论区
		const pageRevUser = [49048256661, 62366974136, 23928713];
		let href = window.location.href;
		let index = href.indexOf("pages/") + 6;
		let pageName = href.substring(index);
		const allRev = $dqs(".custom-vstar-review-widget,#vstar-all-reviews");
		if (allRev && allRev.id === "vstar-all-reviews") {
			v.isRevPage = true;
		}
		v.allRev = allRev;
		if (pageRevUser.includes(shop_id) || allRev || pageName === "reviews") {
			let nameArr = [
				"clienti-soddisfatti",
				"shop-reviews",
				"review-page",
				"producto-mini",
				"avis-menviking",
				"customer-reviews",
			];
			if (
				nameArr.includes(pageName) ||
				pageName === "reviews" ||
				(allRev && allRev.id === "vstar-all-reviews") ||
				(allRev &&
					allRev.className === "custom-vstar-review-widget" &&
					!v.productId)
			) {
				this.isPageReview = true;
			}
		}
	},
	async getUserNeedData() {
		//Mark 获取用户需求数据
		const { pageType, themeId } = v;
		const u = this;
		if (
			v.isHomePage ||
			v.isProPage ||
			v.otherPageColl ||
			v.isCollPage ||
			u.isPageReview
		) {
			let needData, needErr;
			if (typeof vstar_custom_info !== "undefined") {
				needData = vstar_custom_info;
				needErr = null;
			} else {
				[needErr, needData] = await requests.getUserNeed();
			}
			if (needErr === null) {
				// 移动评论区信息
				for (let k in needData) {
					const list = needData[k].list;
					const len = list.length;
					if (len !== 0) {
						for (let i = 0; i < list.length; i++) {
							const item = list[i];
							const tp = item.page || item.target_page;
							const tid = item.theme_id;
							if (tp) {
								if (tp.indexOf(pageType) === -1 && tp !== "all") {
									continue;
								}
							}
							if (tid && themeId != tid) {
								continue;
							}
							if (Object.keys(u[k]).length !== 0) {
								if (k === "custom_style_info") {
									u[k].css_style_code += item.css_style_code;
								} else {
									u[k] = item;
								}
							} else if (Object.keys(u[k]).length === 0) {
								u[k] = item;
							}
						}
					}
				}

				if (u["star_insert_adaptation_info"]) {
					const info = u["star_insert_adaptation_info"];
					if (info.product_detail_star) {
						u.customProTarSel = info.product_detail_star;
					}
					if (info.product_list_star) {
						u.customCollTarSel = info.product_list_star;
					}
				}
			}
		}
	},
};

export default user_need;

// module.exports: user_need
